$fireColor: #ff9800;
$fireColor2: #ffc107;
$candleBody: white;
.candle {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    .candle__cotton {
        position: relative;
        height: 16px;
        width: 4px;
        background: white;
        border-top-right-radius: 10px;
        filter: blur(1px);
        .candle__cotton_fire {
            display: none;
            position: absolute;
            bottom: 0px;
            left: 40%;
            width: 16px;
            height: 20px;
            border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
            background: $fireColor;
            transform: translate(-50%, 0%);
            animation: dance-fire 3s infinite linear;
            filter: blur(1px) drop-shadow(0px -2px 11px orange);
        }
    }
    .candle_body {
        height: 80px;
        width: 30px;
        background-image: linear-gradient(90deg, #ffffffba, white);
        border-radius: 15px 2px 2px 2px/1px 1px 2px 1px;
    }
    &.candle--burning {
        .candle__cotton_fire {
            display: block;
        }
        .candle_body {
            background-image: linear-gradient(180deg, #ffaa08f2, #ffffffba 10%, white);
        }
    }
}

.candle-input{
    .light-input{
        color: $fireColor;
        outline: none;
        background-color: transparent;
        border:none;
        border-bottom: 1px ridge wheat;
        caret-color:wheat
    }
    .light-tag-text{
        color: $fireColor;
    }
    .light-time-text{
        color: $fireColor2;
    }
}
@keyframes dance-fire {
    59%,
    89% {
        left: 40%;
        width: 15px;
        height: 21px;
    }
    90%,
    0%,
    7%,
    15%,
    23%,
    31%,
    39%,
    47%,
    55% {
        left: 40.8%;
        width: 16px;
        height: 20px;
        background: $fireColor2;
    }
    94%,
    3%,
    11%,
    19%,
    27%,
    35%,
    43%,
    51%,
    58% {
        left: 41.2%;
        width: 16px;
        height: 20px;
        background: $fireColor;
    }
}
